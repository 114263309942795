export default {
  name: "SimpleLayout",
  path: "/",
  component: () => import(/* webpackChunkName: "SimpleLayout" */ "../layouts/SimpleLayout.vue"),
  children: [
    {
      path: "/",
      name: "collaborator",
      component: () => import(/* webpackChunkName: "collaborator" */ "../views/CollaboratorView.vue"),
    },
    {
      path: "/configuration",
      name: "configuration",
      component: () => import(/* webpackChunkName: "configuration" */ "../views/ConfigurationView.vue"),
    },
    {
      path: "/economic",
      name: "economic",
      component: () => import(/* webpackChunkName: "economic" */ "../views/EconomicExplotationView.vue"),
    },
    {
      path: "/explotation",
      name: "explotation",
      component: () => import(/* webpackChunkName: "explotation" */ "../views/ExplotationView.vue"),
    },
    {
      path: "/individualDetails/:id",
      name: "individualDetails",
      component: () => import(/* webpackChunkName: "individual" */ "../views/IndividualDetailsView.vue"),
    },
  ],
};
